<template>
  <div>

    <v-card flat tile>
      <v-card-title>
        Nouvelle campagne
      </v-card-title>

      <v-card-subtitle>
        Tous les champs indiqués avec une étoile <span class="red--text">(*)</span> sont obligatoires.
      </v-card-subtitle>
    </v-card>

    <v-tabs v-model="stepVal">

      <v-tab active-class="active-tab" class="text-none pointer-none">
        <v-icon left>mdi-clock</v-icon>
        Info&Plannification
      </v-tab>

      <v-tab active-class="active-tab" class="text-none pointer-none">
        <v-icon left>mdi-map</v-icon>
        Affectation
      </v-tab>

      <v-tab active-class="active-tab" class="text-none pointer-none">
        <v-icon left>mdi-chart-areaspline</v-icon>
        Objectifs
      </v-tab>

      <v-tab active-class="active-tab" class="text-none pointer-none">
        <v-icon left>mdi-file-tree-outline</v-icon>
        Marques&Produits
      </v-tab>

      <v-tab active-class="active-tab" class="text-none pointer-none">
        <v-icon left>mdi-gift</v-icon>
        Cadeaux
      </v-tab>

    </v-tabs>
  </div>
</template>

<script>
export default {
    name: "Tabs",
    props: {
        step: {
            type: Number
        }
    },
    computed: {
        stepVal: {
            get: function () {
                return this.step;
            },
            set: function (newValue) {
                this.$emit('update:step', newValue)
            }
        },
    }
}
</script>

<style scoped>
.pointer-none {
    /*pointer-events: none*/
}
</style>