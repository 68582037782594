<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="secondary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-divider/>

      <v-card-text class="pt-10 px-15">

        <v-row justify="center">
          <v-switch v-model="form.with_objectives" color="secondary" label="Campagne avec des objectifs"/>
        </v-row>

        <v-row v-if="form.with_objectives">

          <v-col cols="6">
            <v-text-field v-model="form.day_global_objective"
                          v-number
                          dense
                          hide-details
                          label="Objectif global par jour" outlined/>
          </v-col>

          <v-col cols="6">
            <v-text-field v-model="form.animator_global_objective"
                          v-number
                          dense
                          hide-details
                          label="Objectif global par animateur" outlined/>
          </v-col>

          <v-col cols="6">
            <v-text-field v-model="form.day_refusal_objective"
                          v-number
                          dense hide-details label="Objectif refus par jour" outlined/>
          </v-col>

          <v-col cols="6">
            <v-text-field v-model="form.animator_refusal_objective"
                          v-number
                          dense
                          hide-details label="Objectif refus par animateur" outlined/>
          </v-col>

          <v-col cols="6">
            <v-text-field v-model="form.day_switch_objective"
                          v-number
                          dense
                          hide-details label="Objectif switch par jour" outlined/>
          </v-col>

          <v-col cols="6">
            <v-text-field v-model="form.animator_switch_objective"
                          v-number
                          dense
                          hide-details label="Objectif switch par animateur" outlined/>
          </v-col>

        </v-row>

        <v-row v-else>
          <v-col class="text-center" cols="12">
            <div class="d-block">
              <img :src="require('@/assets/objective.svg')" alt="" width="200"/>
            </div>
          </v-col>
        </v-row>

      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn color="primary"
               text
               @click="$emit('back')"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Retour
        </v-btn>
        <v-spacer/>
        <v-btn color="secondary"
               depressed
               @click="$emit('next')">
          Suivant
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>
  </div>
</template>

<script>
import Tabs from "@/views/campaigns/create/components/Tabs.vue";

export default {
    props: {
        step: {
            type: Number
        },
        form: Object
    },
    components: {Tabs},
    data() {
        return {
            progress: 40
        }
    },
    mounted() {
        this.$vuetify.goTo(0)
        let _vm = this
        setTimeout(function () {
            _vm.progress += 20
        }, 500)
    },
    computed: {
        stepVal: {
            get: function () {
                return this.step;
            },
            set: function (newValue) {
                this.$emit('update:step', newValue)
            }
        },
    }
}
</script>

<style scoped>

</style>