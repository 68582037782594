<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" lg="10">

          <v-alert v-if="Object.keys(errors).length" border="top" dismissible prominent type="error">
            <ul class="fs-15">
              <li v-for="(err,index) in errors" :key="index">
                {{ err.toString() }}
              </li>
            </ul>
          </v-alert>

          <Overlay :overlay="isLoading"/>

          <InfoPlanning v-if="step === 0"
                        :form.sync="form"
                        :step.sync="step"
                        @next="step = 1"/>

          <Assignment v-if="step === 1"
                      :form.sync="form"
                      :step.sync="step"
                      @back="step = 0"
                      @next="step = 2"/>

          <Objectives v-if="step === 2"
                      :form.sync="form"
                      :step.sync="step"
                      @back="step = 1"
                      @next="step = 3"/>

          <BrandsProducts v-if="step === 3"
                          :form.sync="form"
                          :step.sync="step"
                          @back="step = 2"
                          @next="step = 4"/>

          <Gifts v-if="step === 4"
                 :form.sync="form"
                 :step.sync="step"
                 @back="step = 3"
                 @save="save"/>


          <ConfirmDialog ref="confirmDialog"/>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import InfoPlanning from "@/views/campaigns/create/components/InfoPlanning.vue";
import Assignment from "@/views/campaigns/create/components/Assignment.vue";
import Gifts from "@/views/campaigns/create/components/Gifts.vue";
import Objectives from "@/views/campaigns/create/components/Objectives.vue";
import BrandsProducts from "@/views/campaigns/create/components/BrandsProducts.vue";
import {HTTP} from "@/http-common";

export default {
  components: {Assignment, InfoPlanning, Gifts, Objectives, BrandsProducts},
  data() {
    return {
      isLoading: false,
      errors: {},
      step: 0,
      form: {
        name: null,
        type: 'switch',
        start_date: null,
        end_date: null,
        description: null,
        customer_info: 'required',
        note_contact: true,
        image: null,

        products: [],
        brands: [],

        agency: {},
        regionals: [],
        supervisors: [],

        with_game: false,
        game: {
          name: '',
          gifts: []
        },
        gifts: [],
        gift: null, //When campaign without games
        digital_amount: null, //When campaign without games
        recipient_digital_amount: null, //When campaign without games
        min_qty_product_win: 1, //Minimum product sales quantity to win a gift

        choice_gift_type: "unique",
        choice_gifts: [],

        with_objectives: false,
        day_global_objective: null,
        animator_global_objective: null,
        day_refusal_objective: null,
        animator_refusal_objective: null,
        day_switch_objective: null,
        animator_switch_objective: null,
      }
    }
  },
  methods: {
    async save() {
      if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir enregistrer cette campagne ?', {
        color: 'orange',
        agreeText: 'Enregistrer',
        icon: 'mdi-information-outline',
      })) {
        this.isLoading = true
        this.errors = {}
        HTTP.post('/campaigns/store', this.form).then(() => {
          this.$successMessage = "Cette campagne a été enregistré avec succés"
          this.isLoading = false
          this.$router.push('/campaigns')
        }).catch(err => {
          console.log(err)
          this.isLoading = false
          this.$vuetify.goTo(0)
          this.errors = err.response.data.errors
          this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        })
      }
    }
  },
  created() {
    if (this.$store.state.user.role.key === 'agency') {
      let agency = this.$store.state.user
      this.form.agency = {
        id: agency.id,
        name: agency.name,
        photo: agency.photo,
        role: agency.role,
      }
    }
  }
}
</script>

<style scoped>

</style>