<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="secondary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-divider/>

      <v-card-text class="pt-10 px-15">

        Marques concernés *

        <div v-if="isLoading" class="d-flex mb-3">
          <v-skeleton-loader v-for="item in 5" :key="item" class="mx-1" type="chip"/>
        </div>

        <v-chip-group v-else
                      v-model="form.brands"
                      class="mb-3"
                      color="primary"
                      column
                      multiple
        >
          <v-chip v-for="(brand,i) in brands"
                  :key="i"
                  :value="brand.id"
                  filter
                  outlined
          >
            {{ brand.name }}
          </v-chip>
        </v-chip-group>

        <v-autocomplete v-model="form.products"
                        :disabled="isLoading"
                        :items="sortedListProducts"
                        :loading="isLoading"
                        chips
                        class="autocomplete"
                        dense
                        item-text="name"
                        item-value="id"
                        label="Produits concernés"
                        multiple
                        outlined
                        small-chips
        >
          <template v-slot:selection="data">
            <v-chip :input-value="data.selected"
                    :style="{margin : '2px'}"
                    close
                    small
                    v-bind="data.attrs"
                    @click="data.select"
                    @click:close="remove(data.item)"
            >
              <v-avatar left tile>
                <v-img v-if="data.item.image" :src="$baseUrl + data.item.image"></v-img>
                <v-img v-else :src="require('@/assets/no-product.png')"></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar tile>
                <v-img v-if="data.item.image"
                       :src="$baseUrl + data.item.image"
                       max-height="35"
                       max-width="35"
                       min-height="35"
                       min-width="35"></v-img>
                <v-img v-else :src="require('@/assets/no-product.png')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

        <v-text-field v-model="form.min_qty_product_win"
                      v-number
                      dense
                      label="Quantité minimale  de produit   pour gagner un cadeau *"
                      outlined type="number"/>

      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn color="primary"
               text
               @click="$emit('back')"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Retour
        </v-btn>
        <v-spacer/>
        <v-btn color="secondary"
               depressed
               @click="$emit('next')">
          Suivant
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>
  </div>
</template>

<script>
import Tabs from "@/views/campaigns/create/components/Tabs.vue";
import axios from "axios";
import {HTTP} from "@/http-common";

export default {
    props: {
        step: {
            type: Number
        },
        form: Object
    },
    components: {Tabs},
    data() {
        return {
            progress: 60,
            isLoading: false,
            brands: [],
            products: [],
        }
    },
    methods: {
        getProductsAndBrands() {
            this.isLoading = true
            axios.all([
                HTTP.get('/brands'),
                HTTP.get('/products')
            ]).then(axios.spread((res1, res2) => {
                this.brands = res1.data.data
                this.products = res2.data.data
                this.isLoading = false
            })).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        remove(item) {
            const index = this.form.products.findIndex(el => el === item.id)
            if (index >= 0) this.form.products.splice(index, 1)
        },
    },
    created() {
        this.getProductsAndBrands()
    },
    mounted() {
        this.$vuetify.goTo(0)
        let _vm = this
        setTimeout(function () {
            _vm.progress += 20
        }, 500)
    },
    computed: {
        stepVal: {
            get: function () {
                return this.step;
            },
            set: function (newValue) {
                this.$emit('update:step', newValue)
            }
        },
        sortedListProducts() {
            if (this.form.brands.length) {
                let brandIds = this.form.brands
                return this.products.filter(item => brandIds.includes(item.brand_id))
            } else {
                return []
            }
        }
    }
}
</script>

<style scoped>

</style>