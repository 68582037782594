<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="secondary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-divider/>

      <v-card-text class="pt-10 px-15">

        <v-text-field v-model="form.name" dense label="Nom *" outlined/>

<!--        <v-radio-group label="Type de campagne *"  v-model="form.type">-->
<!--          <v-radio v-for="(type,i) in types" :key="i" :label="type.name" :value="type.value"></v-radio>-->
<!--        </v-radio-group>-->


        <v-row>

          <v-col>
            <v-menu v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="form.start_date"
                              clearable
                              dense
                              label="Date de début *"
                              outlined
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:prepend-inner="menu1 = true"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.start_date"
                             :min="dateNow"
                             @change="[menu1 = false,form.end_date='']"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-menu v-model="menu2"
                    :close-on-content-click="false"
                    max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="form.end_date"
                              :disabled="!form.start_date"
                              clearable
                              dense
                              label="Date de fin *"
                              outlined
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:prepend-inner="menu2 = true"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.end_date"
                             :min="form.start_date"
                             @change="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-textarea v-model="form.description"
                    dense
                    hide-details
                    label="Description *"
                    outlined
                    rows="2"/>

        <v-checkbox v-model="form.customer_info"
                    true-value="required"
                   false-value="nullable"
                    hide-details
                    label="Les informations client sont requises"></v-checkbox>

        <v-checkbox v-model="form.note_contact"
                    label="L'animateur doit relever les contacts indirect ?"></v-checkbox>

        <v-layout align-center class="text-center" justify-center>
          <v-flex shrink>
            <div class="profile-img">
              <v-img :src="form.image ? form.image : require('@/assets/no-image.png')"
                     class="rounded-circle"
                     height="90"
                     width="90">
              </v-img>
            </div>

            <v-btn v-if="!form.image"
                   class="btn-upload"
                   color="primary"
                   dark
                   depressed
                   fab
                   x-small
                   @click="onButtonClick">
              <v-icon>mdi-camera</v-icon>
            </v-btn>

            <v-btn v-if="form.image"
                   class="btn-upload"
                   color="red"
                   dark
                   depressed
                   fab
                   x-small
                   @click="form.image = null">
              <v-icon>mdi-close</v-icon>
            </v-btn>

          </v-flex>
        </v-layout>

        <input ref="image"
               accept="image/png,image/jpeg"
               class="d-none"
               type="file"
               @change="uploadImage($event)">

      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="secondary"
               depressed
               @click="$emit('next')">
          Suivant
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import Tabs from "@/views/campaigns/create/components/Tabs.vue";

export default {
    props: {
        step: {
            type: Number
        },
        form: Object
    },
    components: {Tabs},
    data() {
        return {
            types: [
                {name: 'Switch', value: 'switch'},
                {name: 'Dégustation', value: 'tasting'},
                {name: 'Échantillonnage', value: 'sampling'},
                {name: 'Fidélisation', value: 'sale'},
            ],
            progress: 0,
            menu1: false,
            menu2: false,
            dateNow: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        }
    },
    methods: {
        uploadImage(event) {
            let files = event.target.files
            let file = files[0]
            if (file) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.form.image = reader.result
                }
                reader.readAsDataURL(file)
            }
        },
        onButtonClick() {
            window.addEventListener('focus', () => {
            }, {once: true})
            this.$refs.image.click()
        },
    },
    mounted() {
        this.$vuetify.goTo(0)
        let _vm = this
        setTimeout(function () {
            _vm.progress += 20
        }, 500)
    },
    computed: {
        stepVal: {
            get: function () {
                return this.step;
            },
            set: function (newValue) {
                this.$emit('update:step', newValue)
            }
        },
    }
}
</script>

<style scoped>
.profile-img {
    border: solid 1px #e9ebec;
    border-radius: 50%;
    padding: 5px;
}

.btn-upload {
    margin-right: -75px;
    margin-top: -50px
}
</style>