<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="secondary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-divider/>

      <v-card-text class="pt-10 px-15">

        <v-row justify="center">

          <v-switch v-model="form.with_game"
                    color="secondary"
                    label="Campagne avec des jeux"/>

          <v-col v-if="!form.with_game" cols="12">

            <v-radio-group v-model="form.choice_gift_type"
                           color="secondary"
                           label="Choix de cadeau *"
                           row>
              <v-radio label="Unique" value="unique"></v-radio>
              <v-radio label="Multiple" value="multiple"></v-radio>
            </v-radio-group>


            <div v-if="form.choice_gift_type==='unique'">
              <v-autocomplete v-model="form.gift"
                              :disabled="isLoading"
                              :items="gifts"
                              :loading="isLoading"
                              class="autocomplete"
                              dense
                              item-text="name"
                              item-value="id"
                              label="Cadeau *"
                              outlined
                              return-object
                              small-chips
              >
                <template v-slot:selection="data">
                  <v-chip :input-value="data.selected"
                          :style="{margin : '2px'}"
                          small
                          v-bind="data.attrs"
                          @click="data.select"
                  >
                    <v-avatar left>
                      <v-img :src="$baseUrl + data.item.image" contain></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar tile>
                      <v-img :src="$baseUrl + data.item.image" contain></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <v-text-field v-if="form.gift && form.gift.is_digital"
                            v-model="form.digital_amount"
                            v-number
                            dense
                            hide-details
                            label="Entrer le montant envoyé *"
                            outlined
                            suffix="DZD"/>

              <v-radio-group v-if="form.gift && form.gift.is_digital"
                             v-model="form.recipient_digital_amount"
                             label="Sélectionnez le destinataire *">
                <v-radio v-for="(recipient,i) in recipients"
                         :key="i"
                         :label="recipient.name"
                         :value="recipient.value"></v-radio>
              </v-radio-group>
            </div>

            <div v-else>

              <v-skeleton-loader v-if="isLoading"
                                 type="list-item-avatar,list-item-avatar,list-item-avatar"/>

              <v-simple-table v-if="!isLoading"
                              :style="{maxHeight: '400px'}"
                              class="rounded-lg table-border overflow-y-auto">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th></th>
                    <th>Cadeau</th>
                    <th>Montant & Destinataire</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in form.choice_gifts" :key="index"
                      :class="item.is_active ? 'v-data-table__selected' : ''">
                    <td>
                      <v-checkbox v-model="item.is_active"
                                  :false-value="false"
                                  :true-value="true"
                                  class="pa-0 ma-0"
                                  color="secondary"
                                  hide-details
                      />
                    </td>
                    <td>
                      <div class="d-flex align-center py-3">
                        <v-img :src="$baseUrl + item.image"
                               class="rounded zoom-pointer"
                               contain
                               max-height="40"
                               max-width="40"
                               min-height="40"
                               min-width="40"
                               @click="$refs.lightbox.open(item.image)"></v-img>
                        <span class="ml-2">{{ item.name }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex">
                        <v-text-field v-if="item.is_digital"
                                      v-model="item.digital_amount"
                                      v-number
                                      dense
                                      hide-details
                                      outlined
                                      placeholder="Montant *"
                                      suffix="DZD"/>
                        <span v-if="item.is_digital"> &nbsp;</span>
                        <v-select v-if="item.is_digital"
                                  v-model="item.recipient_digital_amount"
                                  :items="recipients"
                                  dense
                                  hide-details
                                  item-text="name"
                                  item-value="value"
                                  outlined
                                  placeholder="Destinataire *"
                        />
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </template>

              </v-simple-table>
            </div>


          </v-col>
        </v-row>

        <v-row v-if="form.with_game" justify="center">

          <v-col cols="12">
            <v-alert border="top" class="fs-14 mb-0" color="primary" prominent text>
              Choisissez un jouet et entrez le pourcentage pour chaque cadeau
            </v-alert>
          </v-col>

          <v-col v-for="(game,ind) in games" :key="ind" :cols="form.game.name !== game.name ? '12' : '12'">
            <v-card class="rounded-lg" outlined>
              <v-card-text>

                <div class="d-flex">
                  <img :src="require('@/assets/'+game.name+'.jpg')"
                       alt=""
                       class="rounded-lg"
                       height="70" width="70">
                  <div class="ml-5">
                    <h3 class="font-weight-medium text-uppercase">
                      {{ game.name }}
                    </h3>
                    <v-checkbox v-model="form.game.name"
                                :hide-details="!form.game.name || form.game.name !== game.name"
                                :value="game.name"
                                color="secondary"
                                label="J'ai choisi"
                                off-icon="mdi-circle"
                                on-icon="mdi-check-circle"/>
                  </div>
                </div>

                <v-skeleton-loader v-if="isLoading"
                                   type="list-item-avatar,list-item-avatar,list-item-avatar"/>

                <v-simple-table v-if="!isLoading && form.game.name === game.name"
                                :style="{maxHeight: '400px'}"
                                class="rounded-lg table-border overflow-y-auto">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th></th>
                      <th>Cadeau</th>
                      <th>Pourcentage & Montant & Destinataire</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in form.game.gifts" :key="index"
                        :class="item.is_active ? 'v-data-table__selected' : ''">
                      <td>
                        <v-checkbox v-model="item.is_active"
                                    :false-value="false"
                                    :true-value="true"
                                    class="pa-0 ma-0"
                                    color="secondary"
                                    hide-details
                        />
                      </td>
                      <td>
                        <div class="d-flex align-center py-3">
                          <v-img :src="$baseUrl + item.image"
                                 class="rounded zoom-pointer"
                                 contain
                                 max-height="40"
                                 max-width="40"
                                 min-height="40"
                                 min-width="40"
                                 @click="$refs.lightbox.open(item.image)"></v-img>
                          <span class="ml-2">{{ item.name }}</span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <v-text-field v-if="item.is_digital"
                                        v-model="item.digital_amount"
                                        v-number
                                        dense
                                        hide-details
                                        outlined
                                        placeholder="Montant *"
                                        suffix="DZD"/>
                          <span v-if="item.is_digital"> &nbsp;</span>
                          <v-select v-if="item.is_digital"
                                    v-model="item.recipient_digital_amount"
                                    :items="recipients"
                                    dense
                                    hide-details
                                    item-text="name"
                                    item-value="value"
                                    outlined
                                    placeholder="Destinataire *"
                          />
                          <span v-if="item.is_digital"> &nbsp;</span>
                          <v-text-field v-model="item.percentage"
                                        v-number
                                        dense
                                        hide-details
                                        outlined
                                        placeholder="Pourcentage *"
                                        suffix="%"/>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>

                </v-simple-table>

              </v-card-text>
            </v-card>
          </v-col>

        </v-row>

      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn color="primary"
               text
               @click="$emit('back')"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Retour
        </v-btn>
        <v-spacer/>
        <v-btn color="secondary"
               depressed
               @click="$emit('save')">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer

        </v-btn>
      </v-card-actions>

    </v-card>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>
import Tabs from "@/views/campaigns/create/components/Tabs.vue";
import {HTTP} from "@/http-common";

export default {
  props: {
    step: {
      type: Number
    },
    form: Object
  },
  components: {Tabs},
  data() {
    return {
      isLoading: false,
      progress: 80,
      gifts: [],
      games: [
        {
          name: 'spintowin',
        },
        {
          name: 'jackpot',
        },
      ],
      recipients: [
        {name: 'Consommateur', value: 'consumer'},
        {name: 'Point de vente', value: 'salepoint'},
        {name: 'Animateur', value: 'animator'},
      ],
    }
  },
  methods: {
    getGifts() {
      this.isLoading = true
      HTTP.get('/gifts').then((res) => {
        this.isLoading = false
        this.gifts = res.data.data

        /**
         * Spin To Win
         * @type {any}
         */
        const gameGiftsLookup = {};
        this.form.game.gifts.forEach(item => {
          gameGiftsLookup[item.id] = item;
        });

        this.form.game.gifts = res.data.data.map(el => {
          const data = gameGiftsLookup[el.id] || {};
          return {
            ...el,
            percentage: data.percentage || null,
            digital_amount: data.digital_amount || null,
            recipient_digital_amount: data.recipient_digital_amount || null,
            is_active: data.is_active || false
          };
        });

        /**
         * For multi choice
         * @type {any}
         */
        const choiceGiftsLookup = {};
        this.form.choice_gifts.forEach(item => {
          choiceGiftsLookup[item.id] = item;
        });

        this.form.choice_gifts = res.data.data.map(el => {
          const data = choiceGiftsLookup[el.id] || {};
          return {
            ...el,
            digital_amount: data.digital_amount || null,
            recipient_digital_amount: data.recipient_digital_amount || null,
            is_active: data.is_active || false
          };
        });

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(item) {
      const index = this.form.gifts.findIndex(el => el.id === item.id)
      if (index >= 0) this.form.gifts.splice(index, 1)
    }
  },
  created() {
    this.getGifts()
  },
  mounted() {
    this.$vuetify.goTo(0)
    let _vm = this
    setTimeout(function () {
      _vm.progress += 20
    }, 500)
  },
  computed: {
    stepVal: {
      get: function () {
        return this.step;
      },
      set: function (newValue) {
        this.$emit('update:step', newValue)
      }
    },
  }
}
</script>

<style>

</style>