<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="secondary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-divider/>

      <v-card-text class="pt-10 px-15">

        <v-autocomplete v-if="roles('admin')"
                        v-model="form.agency"
                        :disabled="agenciesLoading"
                        :items="agencies"
                        :loading="agenciesLoading"
                        chips
                        class="autocomplete"
                        dense
                        item-text="name"
                        item-value="id"
                        label="Agence *"
                        outlined
                        return-object
                        small-chips
                        @change="[form.regionals = [],form.supervisors = [] ,getRegionals()]"

        >
        </v-autocomplete>

        <v-autocomplete v-model="form.regionals"
                        :disabled="regionalsLoading || !form.agency.id"
                        :items="regionals"
                        :loading="regionalsLoading"
                        chips
                        class="autocomplete"
                        deletable-chips
                        dense
                        item-text="name"
                        item-value="id"
                        label="Régionaux *"
                        multiple
                        outlined
                        return-object
                        small-chips
                        @change="getSupervisors()"
        >
        </v-autocomplete>


        <v-autocomplete v-model="form.supervisors"
                        :disabled="supervisorsLoading || !form.regionals.length"
                        :items="supervisors"
                        :loading="supervisorsLoading"
                        chips
                        class="autocomplete"
                        deletable-chips
                        dense
                        item-text="name"
                        item-value="id"
                        label="Superviseurs *"
                        multiple
                        outlined
                        return-object
                        small-chips
        >
        </v-autocomplete>

        <v-row>

          <v-col cols="4">
            <v-card class="rounded-lg" outlined>
              <v-card-text class="pa-1">

                <div v-if="!form.supervisors.length">
                  <v-alert border="top" class="mb-0 fs-14" color="primary" prominent text>
                    Utilisez le filtre ci-dessus pour afficher des superviseur
                  </v-alert>
                </div>

                <v-list-item-group v-else
                                   v-model="selectedSupervisorIndex" mandatory>
                  <v-list dense nav>

                    <v-list-item v-for="(item,index) in form.supervisors"
                                 :key="index"
                                 :value="index"
                                 color="primary">

                      <v-list-item-avatar v-if="item.photo">
                        <v-img :src="$baseUrl + item.photo"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-avatar v-else
                                          :class="$func.randomColor()"
                                          class="font-weight-medium"
                      >
                        {{ $func.avatarName(item.name) }}
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.regional }}</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text>
                          <v-icon color="red" @click.stop="removeSupervisor(index)">mdi-close</v-icon>
                        </v-list-item-action-text>
                      </v-list-item-action>

                    </v-list-item>

                  </v-list>
                </v-list-item-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="8">
            <v-card class="rounded-lg" outlined>
              <v-card-title class="fs-16">Animateurs</v-card-title>
              <v-divider/>
              <v-card-text>

                <v-alert v-if="!form.supervisors.length"
                         border="top" class="mb-0 fs-14" color="primary"  prominent text>
                  Utilisez le filtre ci-dessus pour afficher des animateurs
                </v-alert>

                <v-simple-table v-else
                                class="rounded-lg table-border">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Concerné</th>
                      <th>Name</th>
                      <th>Routing</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(animator,index) in form.supervisors[selectedSupervisorIndex].animators" :key="index">
                      <td>
                        <v-switch v-model="animator.is_selected" class="pa-0 ma-0" color="secondary" dense
                                  hide-details/>
                      </td>
                      <td>
                        <div class="d-flex align-center">

                          <v-avatar v-if="animator.photo" size="35">
                            <v-img :src="$baseUrl + animator.photo"
                                   class="zoom-pointer"
                                   @click="$refs.lightbox.open(item.photo)"></v-img>
                          </v-avatar>

                          <v-avatar v-else
                                    :class="$func.randomColor()"
                                    class="font-weight-medium"
                                    size="35">
                            {{ $func.avatarName(animator.name) }}
                          </v-avatar>


                          <span class="ml-2">{{ animator.name }}</span>
                        </div>

                      </td>
                      <td>
                        <v-btn :color="countRouting(animator.dates) ? 'secondary' : 'primary'"
                               :disabled="!animator.is_selected"
                               depressed
                               small
                               @click="openRoutingDialog(animator)">
                          Routing
                          <v-icon right small>
                            mdi-arrow-right
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-card-text>
            </v-card>
          </v-col>

        </v-row>

      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn color="primary"
               text
               @click="$emit('back')"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Retour
        </v-btn>
        <v-spacer/>
        <v-btn color="secondary"
               depressed
               @click="$emit('next')">
          Suivant
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>

    <RoutingDialog v-if="form.supervisors.length"
                   ref="routingDialog"
                   :dates.sync="dates"
    />

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>
import Tabs from "@/views/campaigns/create/components/Tabs.vue";
import {HTTP} from "@/http-common";
import RoutingDialog from "@/views/campaigns/create/components/RoutingDialog.vue";
import * as moment from 'moment';

export default {
    props: {
        step: {
            type: Number
        },
        form: Object
    },
    components: {RoutingDialog, Tabs},
    data() {
        return {
            progress: 20,
            agenciesLoading: false,
            regionalsLoading: false,
            supervisorsLoading: false,
            agencies: [],
            regionals: [],
            supervisors: [],

            selectedSupervisorIndex: 0,
            dates: [],
        }
    },
    methods: {
        removeSupervisor(index) {
            this.form.supervisors.splice(index, 1)
        },
        openRoutingDialog(animator) {
            this.dates = animator.dates
            this.$refs.routingDialog.open(animator)
        },

        /**
         * 1
         */
        getAgencies() {
            this.agenciesLoading = true
            HTTP.get('/campaigns/agencies').then((res) => {
                this.agenciesLoading = false
                this.agencies = res.data.data
            }).catch(err => {
                this.agenciesLoading = false
                console.log(err)
            })
        },

        /**
         * 2
         */
        getRegionals() {
            this.regionalsLoading = true
            HTTP.get('/campaigns/regionals',
                {
                    params: {
                        parent_id: this.form.agency.id
                    }
                }).then((res) => {
                this.regionalsLoading = false
                this.regionals = res.data.data
            }).catch(err => {
                this.regionalsLoading = false
                console.log(err)
            })
        },

        /**
         * 3
         */
        getSupervisors() {
            this.supervisorsLoading = true
            HTTP.get('/campaigns/supervisors', {
                params: {
                    regional_ids: this.form.regionals.map(el => el.id)
                }
            }).then((res) => {
                this.supervisorsLoading = false

                /**
                 * parse data
                 */

                let arr = res.data.data.map(supervisor => {
                    return {
                        id: supervisor.id,
                        name: supervisor.name,
                        photo: supervisor.photo,
                        regional: supervisor.regional,
                        animators: supervisor.animators.map(animator => {
                            return {
                                is_selected: false,
                                id: animator.id,
                                name: animator.name,
                                photo: animator.photo,
                                dates: this.getCampaignDates(),
                            }
                        }),
                    }
                })
                this.supervisors = JSON.parse(JSON.stringify(arr))
            }).catch(err => {
                this.supervisorsLoading = false
                console.log(err)
            })
        },
        /**
         *
         * @returns {*[]}
         */
        getCampaignDates() {
            let dateArray = [];
            let startDate = moment(this.form.start_date);
            let stopDate = moment(this.form.end_date);

            while (startDate <= stopDate) {
                dateArray.push({
                    date: moment(startDate).format('YYYY-MM-DD'),
                    salepoints: [],
                })
                startDate = moment(startDate).add(1, 'days');
            }

            return dateArray
        },

        /**
         *
         * @param dates
         * @returns {boolean}
         */
        countRouting(dates) {
            let res = false
            dates.map(date => {
                if (date.salepoints.length) {
                    res = true
                }
            })
            return res
        }

    },
    created() {

        if (this.$store.state.user.role.key === 'admin') {
            this.getAgencies()
        }

        if (this.form.agency.id) {
            this.getRegionals()
        }

        if (this.form.regionals.length) {
            this.getSupervisors()
        }
        /***
         * When update campaign dates
         */
        if (this.form.supervisors.length) {
            this.form.supervisors.map(sup => {
                sup.animators.map((ani) => {

                    let newArrDates = []
                    let campaignDates = this.getCampaignDates()

                    campaignDates.map(date => {
                        let findAniDate = ani.dates.find(aniDate => aniDate.date === date.date)
                        if (findAniDate) {
                            newArrDates.push({
                                date: findAniDate.date,
                                salepoints: findAniDate.salepoints,
                            })
                        } else {
                            newArrDates.push({
                                date: date.date,
                                salepoints: [],
                            })
                        }
                    })

                    ani.dates = newArrDates
                })
            })
        }
    },
    mounted() {
        this.$vuetify.goTo(0)
        let _vm = this
        setTimeout(function () {
            _vm.progress += 20
        }, 500)
    },
    computed: {
        stepVal: {
            get: function () {
                return this.step;
            },
            set: function (newValue) {
                this.$emit('update:step', newValue)
            }
        },
    }
}
</script>

<style scoped>

</style>